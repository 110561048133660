import apiClient from '@services/interceptors/authInterceptor';
import { isJson } from '@utils/stringUtils';

import { FetchIfNotLatestTermsConditionsResponseSchema } from '@schemas/termsAndConditionsSchemas';

const fetchIfNotLatestTermsConditions = async () => {
  try {
    // the response can either be a blob or string that needs to be parsed
    const { data: blobData, headers } = await apiClient.get(
      `${process.env.PLATFORM_APP_MANAGEMENT_API}/v0/users/termsConditions/fetchIfNotLatest`,
      {
        responseType: 'blob',
      },
    );
    // Convert the Blob into text
    const textData = await blobData.text();

    let data;

    if (isJson(textData)) {
      data = JSON.parse(textData);
    } else {
      // If it's neither a valid JSON, treat it as a Blob
      data = blobData;
    }

    // Validate the response with Zod schema
    const result = FetchIfNotLatestTermsConditionsResponseSchema.parse(data);
    return { data: result, headers };
  } catch (error) {
    console.error(
      'Error processing response: Please check /termsConditions/fetchIfNotLatest endpoint response has correct format',
    );
  }
};

export default fetchIfNotLatestTermsConditions;
