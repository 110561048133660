import { forwardRef, useState } from 'react';

import { yaml } from '@codemirror/lang-yaml';
import { Diagnostic, linter } from '@codemirror/lint';
import { EditorView } from '@codemirror/view';
import cn from '@pxui/lib/utils';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror, {
  type ReactCodeMirrorProps,
  type ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import parser, { YAMLException } from 'js-yaml';

const myTheme = createTheme({
  settings: {
    background: '#161616',
    caret: '#fff',
    foreground: '#75baff',
    gutterBackground: '#161616',
    gutterBorder: 'transparent',
    lineHighlight: 'transparent',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
  },
  styles: [],
  theme: 'dark',
});

export interface CodeAreaProps extends ReactCodeMirrorProps {
  externalErrors?: string[];
  lintYaml?: boolean;
}

const CodeArea = forwardRef<ReactCodeMirrorRef, CodeAreaProps>(
  (
    {
      className,
      onChange,
      readOnly,
      value,
      lintYaml = true,
      height = '100%',
      externalErrors = [],
      placeholder = 'There are no parameters defined for this pipeline...',
    },
    ref,
  ) => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const yamlLinter = linter((view) => {
      const diagnostics: Diagnostic[] = [];
      const document = view.state.doc;

      try {
        parser.load(document.toString());
        setErrorMsg(null);
      } catch (error: any) {
        if (error instanceof YAMLException) {
          const lineNumber = error.mark.line;
          const line = document.line(lineNumber + 1);
          const { to, from } = line;
          const severity = 'error';

          const errorMessage = error.message;
          const bracketIndex = errorMessage.indexOf(')');
          const errorSubstring = errorMessage
            .substring(0, bracketIndex + 1)
            .trim();

          const errorResult =
            errorSubstring.charAt(0).toUpperCase() + errorSubstring.slice(1);

          diagnostics.push({
            from,
            message: errorResult,
            severity,
            to,
          });

          setErrorMsg(errorResult);
        }
      }
      return diagnostics;
    });

    const overrideClasses =
      '[&>*]:text-white [&>*]:code-1 [&_.cm-editor]:rounded [&_.cm-content]:p-0 [&_.cm-focused]:outline-none [&_.cm-scroller]:rounded [&_.cm-scroller]:p-2 [&_.cm-line]:p-0 [&_.cm-gutters]:hidden [&_.cm-tooltip-lint]:hidden [&_.cm-placeholder]:text-placeholder';
    const lineErrorClasses =
      '[&_.cm-line]:relative [&_.cm-lintRange-error]:bg-none [&_.cm-lintRange-error]:before:bg-danger/25 [&_.cm-lintRange-error]:before:w-[calc(100%+16px)] [&_.cm-lintRange-error]:before:h-full [&_.cm-lintRange-error]:before:-left-2 [&_.cm-lintRange-error]:before:top-0 [&_.cm-lintRange-error]:before:absolute';

    const codeAreaClasses = cn(
      'border border-solid transition-colors rounded',
      {
        'border-auxiliary-error': errorMsg || externalErrors.length,
        'border-transparent': !errorMsg && !externalErrors.length,
        'pointer-events-none opacity-50': readOnly,
      },
      className,
      overrideClasses,
      lineErrorClasses,
    );

    const getErrorsClasses = (condition: boolean) =>
      cn('text-error label-2 transition-opacity flex flex-col gap-2', {
        'opacity-0': !condition,
        'opacity-100': condition,
      });

    return (
      <div className="flex flex-col gap-2 w-full">
        <CodeMirror
          className={codeAreaClasses}
          readOnly={readOnly}
          value={value}
          theme={myTheme}
          placeholder={placeholder}
          height={height}
          basicSetup={{
            foldGutter: false,
            lineNumbers: false,
          }}
          contentEditable={readOnly}
          extensions={
            lintYaml ? [yaml(), yamlLinter, EditorView.lineWrapping] : undefined
          }
          onChange={onChange}
          ref={ref}
          data-testid="code-area"
        />
        <div className={getErrorsClasses(!!errorMsg)}>{errorMsg}</div>
        <div className={getErrorsClasses(externalErrors.length > 0)}>
          {externalErrors?.map((error) => (
            <span key={error}>{`Parameter "${error}" in not supported`}</span>
          ))}
        </div>
      </div>
    );
  },
);

CodeArea.displayName = 'CodeArea';

export { CodeArea };
